<template lang="pug">
  transition(name='fade')
    b-card.ls-panel-current-model.card-white.card-no-gutters.shadow(no-body)
      b-card-body.d-flex.justify-content-start.align-items-center
        template(v-if='panelShow')
          .panel-picture.mr-4
            b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.mr-3.shadow(
              :src='portraitPath'
              variant='white'
              alt='')
              template(v-if='!portraitPath' #default)
                .wc-bubble-content.wc-bubble-linked.bg-primary
                  .i-wrapper
                    i.fal.fa-fw.fa-2x.text-white(:class='iconClass')

          .panel-info(v-if='isOrganization')
            .panel-info-name.font-weight-bold
              | {{ panel.data.attributes.name }}
            .panel-info-identity.small.text-muted.text-break(v-if="$getDeep(panel.rs, 'identity.attributes.username')")
              | @{{ $getDeep(panel.rs, 'identity.attributes.username') }}

          .panel-info(v-else)
            .panel-info-profile(v-if='panel.rs.profile')
              .font-weight-bold
                | {{ $getDeep(panel.rs, 'profile.attributes.firstname') }} {{ $getDeep(panel.rs, 'profile.attributes.lastname') }}
            .panel-info-identity.small.text-muted.text-break(v-if="$getDeep(panel.rs, 'identity.attributes.username')")
              | @{{ $getDeep(panel.rs, 'identity.attributes.username') }}
            .panel-info-email.small.text-break
              | {{ panel.data.attributes.email }}
        template(v-else)
          wc-loaders-heading-avatar
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'

export default {
  name: 'ls-panel-current-model',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
  },
  props: {
    isOrganization: Boolean,
    variant: {
      type: String,
      default: 'small',
    },
    data: Object,
    show: Boolean,
  },
  computed: {
    portraitPath() {
      return this.$getDeep(this.panel.data, `attributes.portrait_${this.variant}_url`)
    },
    iconClass() {
      return `${this.isOrganization ? 'fa-building' : 'fa-user'} ${this.iconClasses}`
    },
  },
  methods: {
    cachePayloadFunc() {
      return this.isOrganization
        ? { organization_id: this.$store.getters['auth/currentOrganization'].sub }
        : { account_id: this.$store.getters['auth/currentAccount'].sub }
    },
  },
  watch: {
    isOrganization(value) {
      this.cacheType = `${value ? 'organization' : 'account'}/getPanel`
    },
    panel() {
      this.panel.rs.identity = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.identity.data.type'),
        this.$getDeep(this.panel.data, 'relationships.identity.data.id')
      )
      this.panel.rs.profile = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.profile.data.type'),
        this.$getDeep(this.panel.data, 'relationships.profile.data.id')
      )
      this.$emit('update:data', this.panel)
    },
    panelShow() {
      this.$emit('update:show', this.panelShow)
    },
  },
  data() {
    return {
      cacheType: `${this.isOrganization ? 'organization' : 'account'}/getPanel`,
      cacheOptions: { timeout: this.$wc.conf.cache.session },
      skipNotifications: true,
    }
  },
}
</script>
